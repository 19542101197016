import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import tweets from './data/tweets.js';
import characters from './data/characters.js';
import RandomTweet from './components/RandomTweet.js';
import Character from './components/Character.js';
import ResultModal from './components/ResultModal.js';
import LandingPage from './components/LandingPage';
import GameEndModal from './components/GameEndModal';

function getRandomTweet() {
    return tweets[Math.floor(Math.random() * tweets.length)];
}

class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            tweetsShown: 0,
            correctAnswers: 0,
            randomTweet: getRandomTweet(),
            showModal: false,
            isAnswerCorrect: false,
            modalContent: 'question-result'
        };

        this.handleAnswer = this.handleAnswer.bind(this);
        this.getRandomTweet = this.getRandomTweet.bind(this);
        this.handleShowNextTweet = this.handleShowNextTweet.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handleEndGame = this.handleEndGame.bind(this);
        this.handlePlayGame = this.handlePlayGame.bind(this);
    }

    getRandomTweet() {
        let tweetsShown = this.state.tweetsShown + 1;
        this.setState({
            randomTweet: getRandomTweet(),
            showModal: false,
            isAnswerCorrect: false,
            tweetsShown: tweetsShown
        });
    }

    handleAnswer(answerId) {
        const isAnswerCorrect = answerId === this.state.randomTweet.answerId;
        let currentCorrectAnswers = this.state.correctAnswers;

        let tweetsShown = this.state.tweetsShown + 1;

        if (isAnswerCorrect) {
            currentCorrectAnswers++;
        }

        this.setState({
            correctAnswers: currentCorrectAnswers,
            tweetsShown,
            showModal: true,
            isAnswerCorrect
        });
    }

    handleShowNextTweet() {
        this.setState({
            randomTweet: getRandomTweet(),
            showModal: false,
            isAnswerCorrect: false
        });
    }

    closeModal() {
        this.setState({
            showModal: false
        });
    }

    generateCharacters() {
        return characters.map((character) => {
            return <Character key={character.answerId} data={character} handleAnswer={this.handleAnswer}/>;
        });
    }

    handleEndGame() {
        this.setState({
            modalContent: 'game-result'
        });
    }

    handlePlayGame() {
        this.setState({
            tweetsShown: 0,
            correctAnswers: 0,
            randomTweet: getRandomTweet(),
            showModal: false,
            isAnswerCorrect: false,
            modalContent: 'question-result'
        });
    }

    render() {
        return (
            <div>
                <LandingPage/>
                <nav className="navbar navbar-light bg-light">
                    <a className="navbar-brand" href="/index.html">
                        <img src="img/header_logo.png" alt="trump nickname game"/>
                    </a>
                    <button type="button"
                            className="btn btn-primary"
                            onClick={this.handleShowNextTweet}>Next Tweet
                    </button>
                </nav>
                <div className="container-fluid">
                    <a
                        href="https://teespring.com/stores/trumps-nickname-game-2"
                        target="_blank"
                        rel="noopener noreferrer">
                        <img src="img/giftshop.png" alt="giftshop"/>
                    </a>
                    <div className="row justify-content-md-center">
                        <RandomTweet randomTweet={this.state.randomTweet}/>
                    </div>
                    <div id="character-row" className="row">
                        {this.generateCharacters()}
                    </div>
                    <div id="footer" className="row">
                        <div className="col">
                            {/*<a href="https://adsense.google.com"*/}
                            {/*   target="_blank"*/}
                            {/*   rel="noopener noreferrer">*/}
                            {/*    <img src="img/bottom_ad.png" alt="bottom ad"/>*/}
                            {/*</a>*/}
                        </div>
                    </div>
                    {this.state.modalContent === 'question-result' ?
                        <ResultModal showNextTweet={this.handleShowNextTweet}
                                     isAnswerCorrect={this.state.isAnswerCorrect}
                                     closeModal={this.closeModal}
                                     onEndGame={this.handleEndGame}
                                     showModal={this.state.showModal}
                                     tweetsShown={this.state.tweetsShown}
                                     correctAnswers={this.state.correctAnswers}
                        /> : <GameEndModal showNextTweet={this.handleShowNextTweet}
                                           isAnswerCorrect={this.state.isAnswerCorrect}
                                           closeModal={this.closeModal}
                                           onEndGame={this.handleEndGame}
                                           showModal={this.state.showModal}
                                           tweetsShown={this.state.tweetsShown}
                                           correctAnswers={this.state.correctAnswers}
                                           onPlayGame={this.handlePlayGame}
                        />}

                </div>
            </div>
        );
    }
}

export default App;
