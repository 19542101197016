import React from 'react';
import {Button, Modal} from 'react-bootstrap';
import {SignupForm} from './SignupForm';

export default class GameEndModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            animation: true,
            centered: true
        };

        this.handleClose = this.handleClose.bind(this);
        this.handleOnHide = this.handleOnHide.bind(this);
        this.handleEndGame = this.handleEndGame.bind(this);
        this.handlePlayGame = this.handlePlayGame.bind(this);
    }

    handleClose() {
        this.props.closeModal()
    }

    handleOnHide() {
        this.props.closeModal()
    }

    handleEndGame() {
        this.props.onEndGame();
    }

    handlePlayGame() {
        this.props.onPlayGame();
    }

    render() {
        // const answerStatusImgSrc = this.props.isAnswerCorrect ? 'img/result/Correct.png' : 'img/result/Incorrect.png';
        // const brickPlusMinusImgSrc = this.props.isAnswerCorrect ? 'img/result/brick-plus.png' : 'img/result/brick-minus.png';
        return (
            <Modal centered={this.state.centered} show={this.props.showModal} onHide={this.handleOnHide} animation={this.state.animation}>
                <Modal.Header>
                    <Modal.Title>Game Over!</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    Result {this.props.correctAnswers}/{this.props.tweetsShown}
                </Modal.Body>

                <Modal.Footer>
                    <SignupForm/>
                </Modal.Footer>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.handleClose}>Close</Button>

                    {/*<Button variant="outline-primary" onClick={this.handleEndGame}>End Game</Button>*/}
                    <Button variant="primary" onClick={this.handlePlayGame}>Play Game</Button>
                </Modal.Footer>
            </Modal>
        );

    }
}
