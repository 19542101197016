import React from 'react';
import MailchimpSubscribe from 'react-mailchimp-subscribe';

export class SignupForm extends React.Component {
    render() {
        const url = "//trumpnicknamegame.us13.list-manage.com/subscribe/post?u=ea6d84985a0d055e270970d3a&amp;id=43c1e13e0e";
        const SimpleForm = () => <MailchimpSubscribe url={url}/>
        return (
            <SimpleForm />
        );
    }

}
