import React from 'react';
import {Button, Modal} from 'react-bootstrap';
import {SignupForm} from './SignupForm';

class ResultModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            animation: false,
            centered: true
        };

        this.handleClose = this.handleClose.bind(this);
        this.handleNextTweet = this.handleNextTweet.bind(this);
        this.handleOnHide = this.handleOnHide.bind(this);
        this.handleEndGame = this.handleEndGame.bind(this);
    }

    handleNextTweet() {
        this.props.showNextTweet();
        this.setState({
            showModal: false
        });
    }

    handleClose() {
        this.props.closeModal()
    }

    handleOnHide() {
        this.props.closeModal()
    }

    handleEndGame() {
        this.props.onEndGame();
    }

    render() {
        const answerStatusImgSrc = this.props.isAnswerCorrect ? 'img/result/Correct.png' : 'img/result/Incorrect.png';
        const brickPlusMinusImgSrc = this.props.isAnswerCorrect ? 'img/result/brick-plus.png' : 'img/result/brick-minus.png';
        return (
            <Modal centered={this.state.centered} show={this.props.showModal} onHide={this.handleOnHide} animation={this.state.animation}>
                <Modal.Header>
                    <Modal.Title><img alt="result" src={answerStatusImgSrc}/></Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <img alt="brick" src={brickPlusMinusImgSrc}/>
                </Modal.Body>

                <Modal.Footer>
                    <SignupForm/>
                </Modal.Footer>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.handleClose}>Close</Button>
                    <Button variant="outline-primary" onClick={this.handleEndGame}>End Game</Button>
                    <Button variant="primary" onClick={this.handleNextTweet}>Next Tweet</Button>
                </Modal.Footer>
            </Modal>
        );

    }
}

export default ResultModal;
