module.exports = [
    {
        'answerId': 0,
        'imgSrc': 'Elizabeth_Warren.png'
    },
    {
        'answerId': 1,
        'imgSrc': 'Hillary_Clinton.png'
    },
    {
        'answerId': 2,
        'imgSrc': 'Joe_Biden.png'
    },
    {
        'answerId': 3,
        'imgSrc': 'Kim_Jong_Un.png'
    },
    {
        'answerId': 4,
        'imgSrc': 'Marco_Rubio.png'
    },
    {
        'answerId': 5,
        'imgSrc': 'Ted_Cruz.png'
    }
];
