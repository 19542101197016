import React from 'react';
import {SignupForm} from './SignupForm';

class LandingPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showLandingPage: true
        };

        this.closeLandingPage = this.closeLandingPage.bind(this);
    }

    closeLandingPage() {
        this.setState({
            showLandingPage: false
        });
    }

    render() {
        if (this.state.showLandingPage) {
            return (
                <div id="landing-page-wrapper">
                    <img id="page-header-img" alt="Trump Nickname Game Header"
                         src="img/landing-page/LandingPage_Header.png"/>
                    <img id="trump-logo" alt="DanaToon Trump Logo" src="img/landing-page/Trump_Logo.png"/>
                    <div className={"btn-play-game-wrapper"}>
                        <button id="btn-play-game" type="button" className="btn" onClick={this.closeLandingPage}>PLAY</button>
                    </div>
                    {/*<p>This is Danatoon’s newest side project. As an artist, his main focus in this project is not to*/}
                    {/*    lean towards either side of the political spectrum but rather to bring to light the silliness*/}
                    {/*    that is our political climate.<br/>*/}
                    {/*    After all, laughter will save us from ourselves!</p>*/}
                    {/*<p>Danatoon is an artist/cartoonist based in Southern California. He has created many awesome things*/}
                    {/*    for many great people! To see the other amazing things, Danatoon has done please visit his*/}
                    {/*    website:*/}
                    {/*</p>*/}
                    <SignupForm />
                    <a href="http://www.danatoonstudios.com" rel="noopener noreferrer" alt="Dana Toons Studio"
                       target="_blank">www.DanaToonStudios.com</a>
                </div>
            );
        } else {
            return null;
        }
    }
}

export default LandingPage;
