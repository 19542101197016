module.exports = [
    {
        'tweetImgSrc': 'Asset_112.png',
        'answerId': 2
    },
    {
        'tweetImgSrc': 'Asset_113.png',
        'answerId': 2
    },
    {
        'tweetImgSrc': 'Asset_114.png',
        'answerId': 2
    },
    {
        'tweetImgSrc': 'Asset_115.png',
        'answerId': 2
    },
    {
        'tweetImgSrc': 'Asset_116.png',
        'answerId': 2
    },
    {
        'tweetImgSrc': 'Asset_117.png',
        'answerId': 2
    },
    {
        'tweetImgSrc': 'Asset_118.png',
        'answerId': 2
    },
    {
        'tweetImgSrc': 'Asset_119.png',
        'answerId': 2
    },
    {
        'tweetImgSrc': 'Asset_120.png',
        'answerId': 2
    },
    {
        'tweetImgSrc': 'Asset_121.png',
        'answerId': 2
    },
    {
        'tweetImgSrc': 'Asset_122.png',
        'answerId': 2
    },
    {
        'tweetImgSrc': 'Asset_125.png',
        'answerId': 0
    },
    {
        'tweetImgSrc': 'Asset_126.png',
        'answerId': 0
    },
    {
        'tweetImgSrc': 'Asset_127.png',
        'answerId': 0
    },
    {
        'tweetImgSrc': 'Asset_128.png',
        'answerId': 0
    },
    {
        'tweetImgSrc': 'Asset_129.png',
        'answerId': 0
    },
    {
        'tweetImgSrc': 'Asset_130.png',
        'answerId': 0
    },
    {
        'tweetImgSrc': 'Asset_131.png',
        'answerId': 0
    },
    {
        'tweetImgSrc': 'Asset_132.png',
        'answerId': 0
    },
    {
        'tweetImgSrc': 'Asset_133.png',
        'answerId': 0
    },
    {
        'tweetImgSrc': 'Asset_135.png',
        'answerId': 0
    },
    {
        'tweetImgSrc': 'Asset_136.png',
        'answerId': 3
    },
    {
        'tweetImgSrc': 'Asset_137.png',
        'answerId': 3
    },
    {
        'tweetImgSrc': 'Asset_138.png',
        'answerId': 3
    },
    {
        'tweetImgSrc': 'Asset_139.png',
        'answerId': 3
    },
    {
        'tweetImgSrc': 'Asset_140.png',
        'answerId': 3
    },
    {
        'tweetImgSrc': 'Asset_141.png',
        'answerId': 3
    },
    {
        'tweetImgSrc': 'Asset_143.png',
        'answerId': 5
    },
    {
        'tweetImgSrc': 'Asset_144.png',
        'answerId': 5
    },
    {
        'tweetImgSrc': 'Asset_145.png',
        'answerId': 5
    },
    {
        'tweetImgSrc': 'Asset_146.png',
        'answerId': 5
    },
    {
        'tweetImgSrc': 'Asset_147.png',
        'answerId': 5
    },
    {
        'tweetImgSrc': 'Asset_148.png',
        'answerId': 5
    },
    {
        'tweetImgSrc': 'Asset_149.png',
        'answerId': 5
    },
    {
        'tweetImgSrc': 'Asset_150.png',
        'answerId': 5
    },
    {
        'tweetImgSrc': 'Asset_151.png',
        'answerId': 5
    },
    {
        'tweetImgSrc': 'Asset_152.png',
        'answerId': 5
    },
    {
        'tweetImgSrc': 'Asset_153.png',
        'answerId': 5
    },
    {
        'tweetImgSrc': 'Asset_154.png',
        'answerId': 5
    },
    {
        'tweetImgSrc': 'Asset_157.png',
        'answerId': 4
    },
    {
        'tweetImgSrc': 'Asset_158.png',
        'answerId': 4
    },
    {
        'tweetImgSrc': 'Asset_159.png',
        'answerId': 4
    },
    {
        'tweetImgSrc': 'Asset_160.png',
        'answerId': 4
    },
    {
        'tweetImgSrc': 'Asset_161.png',
        'answerId': 4
    },


    {
        'tweetImgSrc': 'Asset_163.png',
        'answerId': 1
    },
    {
        'tweetImgSrc': 'Asset_164.png',
        'answerId': 1
    },
    {
        'tweetImgSrc': 'Asset_165.png',
        'answerId': 1
    },
    {
        'tweetImgSrc': 'Asset_166.png',
        'answerId': 1
    },
    {
        'tweetImgSrc': 'Asset_167.png',
        'answerId': 1
    },
    {
        'tweetImgSrc': 'Asset_168.png',
        'answerId': 1
    },
    {
        'tweetImgSrc': 'Asset_169.png',
        'answerId': 1
    },
    {
        'tweetImgSrc': 'Asset_170.png',
        'answerId': 1
    },
    {
        'tweetImgSrc': 'Asset_171.png',
        'answerId': 1
    },
    {
        'tweetImgSrc': 'Asset_172.png',
        'answerId': 1
    },
    {
        'tweetImgSrc': 'Asset_173.png',
        'answerId': 1
    },
    {
        'tweetImgSrc': 'Asset_174.png',
        'answerId': 1
    },
    {
        'tweetImgSrc': 'Asset_175.png',
        'answerId': 1
    },
    {
        'tweetImgSrc': 'Asset_176.png',
        'answerId': 1
    },
    {
        'tweetImgSrc': 'Asset_177.png',
        'answerId': 1
    },
    {
        'tweetImgSrc': 'Asset_178.png',
        'answerId': 1
    },
    {
        'tweetImgSrc': 'Asset_179.png',
        'answerId': 1
    },
    {
        'tweetImgSrc': 'Asset_180.png',
        'answerId': 1
    },
    {
        'tweetImgSrc': 'Asset_181.png',
        'answerId': 1
    },
    {
        'tweetImgSrc': 'Asset_182.png',
        'answerId': 1
    },
    {
        'tweetImgSrc': 'Asset_183.png',
        'answerId': 1
    }
];
