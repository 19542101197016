import React from 'react';

class Character extends React.Component {
    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(e) {
        e.preventDefault();
        this.props.handleAnswer(this.props.data.answerId);
    }

    render() {
        const imgSrc = './img/characters/' + this.props.data.imgSrc;
        return (
            <div key={this.props.data.answerId} className="character choice col-md-4 col-4">
                <img alt="character" src={imgSrc} onClick={this.handleClick}/>
            </div>
        );
    }
}

export default Character;
