import React from 'react';
import {Spinner} from 'react-bootstrap';


function getImageSrc(src) {
    return 'img/tweets/' + src;
}

class RandomTweet extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showSpinner: true
        };

        this.hideSpinner = this.hideSpinner.bind(this);
    }

    hideSpinner() {
        this.setState({
            showSpinner: false
        });
    }

    // TODO: this method is deprecated, but it works!
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.randomTweet.tweetImgSrc !== this.props.randomTweet.tweetImgSrc) {
            this.setState({showSpinner: true});
        }
    }

    render() {
        return (
            <div className="col justify-content-center" id="random-tweet">
                {this.state.showSpinner && (
                    <div className="spinnerContainer" style={{width: '100%', padding: '25px 0'}}>
                        <Spinner variant="primary" animation="border"/>
                    </div>
                )}
                <img style={{visibility: this.state.showSpinner ? 'hidden' : 'visible'}}
                     alt="random tweet"
                     onLoad={this.hideSpinner}
                     src={getImageSrc(this.props.randomTweet.tweetImgSrc)}/>
            </div>
        );
    }
}

export default RandomTweet;
